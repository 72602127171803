<script lang="ts" setup>
const { isSidebar } = defineProps<{
  isSidebar: boolean
}>()

const emit = defineEmits(['close'])

function close() {
  emit('close')
}
</script>

<template>
  <sidebar :is-sidebar="isSidebar" :title="$t('sidebar.walletTitle')" @close="close">
    <settings-wallet-card />
    <wallet-assets />
    <wallet-staking-accounts />
  </sidebar>
</template>
