<script lang="ts" setup>
const { isSidebar } = defineProps<{
  isSidebar: boolean
}>()

const emit = defineEmits(['close'])

function close() {
  emit('close')
}
</script>

<template>
  <sidebar :is-sidebar="isSidebar" :title="$t('sidebar.settingsTitle')" @close="close">
    <settings-wallet-card />
    <settings-priority />
    <settings-language />
    <settings-theme />
    <settings-rpc />
  </sidebar>
</template>

<style lang="scss">
.setting-item {
  padding: $spacing-24 0;

  &:not(:last-child) {
    border-bottom: 1px solid $secondary;
  }

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .connect-wallet {
    width: 100%;
  }
}

body.body--dark {
  .setting-item {
    &:not(:last-child) {
      border-color: $neutral-900;
    }
  }
}
</style>
