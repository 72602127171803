<script lang="ts" setup>
import { PriorityLevel } from '../constants'
import { usePriorityFee } from '../index'

const { t } = useTranslation()

const priorityFee = usePriorityFee()

const levels = computed(() => {
  return [
    { label: t('priorityFee.mode.fast'), value: PriorityLevel.Fast },
    { label: t('priorityFee.mode.turbo'), value: PriorityLevel.Turbo },
    { label: t('priorityFee.mode.ultra'), value: PriorityLevel.Ultra },
  ]
})

const level = ref()

watch([
  () => priorityFee.level,
  () => levels.value], ([l]) => {
  if (l) {
    level.value = levels.value.find(v => v.value === l)
  }
}, { immediate: true })

watch(level, (l) => {
  if (l) {
    priorityFee.level = l.value
  }
})
</script>

<template>
  <j-btn-group v-model="level" :buttons="levels">
    <template #default="{ label, isActive, select }">
      <j-btn :variant="isActive ? 'primary' : 'secondary'" @click="select">
        {{ label }}
      </j-btn>
    </template>
  </j-btn-group>
</template>
