<script lang="ts" setup>
import { useWallet } from '@solana/wallet-adapter-vue'
import disconnectIcon from '~/assets/img/icons/disconnect.svg?raw'
import { shortenAddress } from '~/utils'

const { publicKey, wallet, disconnect } = useWallet()

const address = computed(() => shortenAddress(publicKey.value?.toBase58() || '', 8))
const walletName = computed(() => wallet.value?.adapter.name ?? '')
</script>

<template>
  <div class="setting-item">
    <div v-if="publicKey" class="wallet-card">
      <div class="wallet-card__info">
        <div class="wallet-card__info--addr">
          {{ address }}

          <copy-to-clipboard :text="String(publicKey?.toBase58())" />
        </div>
        <div class="wallet-card__info--name">
          {{ walletName }}
        </div>
      </div>

      <j-tooltip>
        <i class="disconnect-icon" @click="disconnect" v-html="disconnectIcon" />

        <template #content>
          {{ $t('wallet.disconnect') }}
        </template>
      </j-tooltip>
    </div>

    <connect-wallet v-else class-name="connect-wallet-sidebar" />
  </div>
</template>

<style lang="scss">
.wallet-card {
  display: flex;
  padding: $spacing-16;
  align-items: flex-start;
  justify-content: space-between;
  gap: $spacing-12;
  border-radius: $spacing-12;
  background: $neutral-50;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &--addr {
      display: flex;
      align-items: center;
      gap: $spacing-8;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    &--name {
      color: $neutral-600;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .disconnect-icon {
    cursor: pointer;
  }
}

body.body--dark {
  .wallet-card {
    background-color: $dark;

    .disconnect-icon {
      path {
        stroke: #fff;
      }
    }
  }
}
</style>
