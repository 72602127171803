<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core'
import defiIcon from '~/assets/img/tabs/defi-icon.svg?raw'
import directStakingIcon from '~/assets/img/tabs/direct-staking-icon.svg?raw'
import highYieldIcon from '~/assets/img/tabs/high-yield-icon.svg?raw'
import liquidIcon from '~/assets/img/tabs/liquid-staking-icon.svg?raw'

const { width } = useWindowSize()

const route = useRoute()

const tabs = [
  {
    title: 'Liquid Staking',
    path: '/',
    icon: liquidIcon,
  },
  {
    title: 'High-Yield Staking',
    path: '/high-yield',
    icon: highYieldIcon,
  },
  {
    title: 'Direct Staking',
    path: '/direct-staking',
    icon: directStakingIcon,
  },
  {
    title: 'Explore DeFi',
    path: '/defi',
    icon: defiIcon,
  },
]

const tabIndex = ref(0)

const isFixed = computed(() => width.value <= 1024)

const onBeforeTabUpdate = (_v1: number, v2: number, v3: Event) => {
  if (v2 === -1) {
    v3.preventDefault()
  }
}

watch(() => route.path, (p) => {
  const path = p.split('/').find(Boolean)
  const currentTabIdx = tabs.findIndex(t => t.path === `/${path || ''}`)
  tabIndex.value = currentTabIdx

  if (isClient) {
    window.scrollTo(0, 0)
  }
}, { immediate: true })
</script>

<template>
  <div v-if="isFixed" class="navigation-tabs" :class="{ 'fixed-tabs': isFixed, 'hide-active': tabIndex === -1 }">
    <b-tabs :model-value="tabIndex" small card @activate-tab="onBeforeTabUpdate">
      <b-tab
        v-for="tab in tabs"
        :key="tab.title"
        :title="tab.title"
        no-body
        no-key-nav
      >
        <template #title>
          <router-link :to="tab.path" class="tab-wrapper">
            <i v-html="tab.icon" />
            {{ tab.title }}
          </router-link>
        </template>
      </b-tab>
    </b-tabs>
  </div>

  <div v-else class="navigation-tabs" :class="{ 'hide-active': tabIndex === -1 }">
    <b-tabs v-model="tabIndex" small card @activate-tab="onBeforeTabUpdate">
      <b-tab
        v-for="(tab, index) in tabs"
        :key="tab.title"
        :title="tab.title"
        :active="index === tabIndex"
        no-body
        no-key-nav
      >
        <template #title>
          <router-link :to="tab.path">
            {{ tab.title }}
          </router-link>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
.fixed-tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background-color: #fff;
  border-top: 1px solid $secondary;
}
.navigation-tabs {
  height: 88px;
  margin: 0 48px 0 auto;

  @media (max-width: 1100px) {
    margin-right: 0;
  }

  @media (max-width: $breakpoint-md) {
    margin: 0;
  }

  @media (max-width: $breakpoint-xs) {
    height: 60px;
  }

  &.hide-active {
    .nav-item::before {
      display: none !important;
    }
  }

  .tabs,
  .card-header {
    height: 100%;
  }

  .card-header-tabs {
    margin: 0;
    height: 100%;
    flex-wrap: nowrap;
  }

  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    &:has(.active) {
      &:before {
        content: '';
        width: calc(100% - $spacing-40);
        height: 4px;
        background-color: $primary;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);

        @media (max-width: $breakpoint-md) {
          height: 2px;
        }
      }
    }
  }

  .nav-link {
    height: 100%;
    color: $dark;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    padding: 0;

    @media (max-width: $breakpoint-sm) {
      font-size: 14px;
    }

    @media (max-width: $breakpoint-xs) {
      font-size: 10px;
    }

    a {
      color: $dark;
      width: 100%;
      height: 100%;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $spacing-20;

      @media (max-width: $breakpoint-xs) {
        padding: 0;
      }
    }

    &.active {
      .tab-wrapper {
        color: $dark;
      }
    }

    .tab-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      color: $neutral-300;

      svg {
        width: 24px;
        height: 24px;

        @media (max-width: $breakpoint-xs) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .nav-tabs .nav-link {
    &:hover {
      border-color: transparent;
    }
    &.active {
      border-color: transparent;
    }
  }
}

body.body--dark {
  .navigation-tabs {
    .nav-link {
      a {
        color: #fff;
      }
    }
  }

  .fixed-tabs {
    background-color: $dark-bg;
    border-color: $neutral-900;

    .nav-link {
      &.active {
        .tab-wrapper {
          color: #fff;
        }
      }
    }
  }
}
</style>
