<script lang="ts" setup>
import closeIcon from '~/assets/img/icons/cross-icon.svg'
import solIcon from '~/assets/img/tokens/sol.svg'
import { DEFAULT_EXACT_FEE, usePriorityFee } from '../index'

const priorityFee = usePriorityFee()

function reset() {
  priorityFee.exactFee = DEFAULT_EXACT_FEE
}
</script>

<template>
  <j-input v-model="priorityFee.exactFee" only-numbers class="priority-fee-input">
    <template #prepend>
      <img :src="solIcon" alt="sol icon">
    </template>
    <template #append>
      <img :src="closeIcon" alt="sol icon" @click="reset">
    </template>
  </j-input>
</template>

<style lang="scss">
.priority-fee-input {
  .j-input__prepend {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .j-input__append {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}

body.body--dark {
  .priority-fee-input {
    .j-input__append {
      img {
        filter: invert(1);
      }
    }
  }
}
</style>
