<script lang="ts" setup>
const sidebar = ref(false)

function sidebarHandler() {
  sidebar.value = !sidebar.value
}

provide('isSidebar', sidebar)
</script>

<template>
  <j-btn pill variant="secondary" class="settings-btn" @click="sidebarHandler">
    <i-app-setting-icon :class="{ active: sidebar }" />
  </j-btn>

  <settings-sidebar :is-sidebar="sidebar" @close="sidebarHandler" />
</template>

<style lang="scss">
.settings-btn {
  padding: $spacing-12 !important;

  @media (max-width: $breakpoint-sm) {
    padding: $spacing-10 !important;
  }

  .btn-content {
    svg {
      width: 20px;
      height: 20px;
      color: transparent;

      @media (max-width: $breakpoint-sm) {
        width: 18px;
        height: 18px;
      }
    }

    .active {
      transform: rotate(20deg);
    }
  }
}

body.body--dark {
  .settings-btn {
    background-color: $neutral-900;
    border-color: $neutral-900;

    svg {
      filter: invert(1);
    }
  }
}
</style>
