<script lang="ts" setup>
import { Connection } from '@solana/web3.js'
import { ENDPOINTS } from '~/config'

const PINT_INTERVAL = 10_000

const connection = useConnectionStore()

const endpoints = ENDPOINTS.filter(endpoint => endpoint.cluster.includes('mainnet'))
const rpc = ref('')

const isSidebar = inject<Ref<boolean>>('isSidebar')

watch(rpc, (r) => {
  if (r) {
    connection.setRpc(r)
  }
})

const rpcPings = ref<Record<string, number | null>>({})

async function measurePing(endpointUrl: string): Promise<number | null> {
  try {
    const connection = new Connection(endpointUrl, 'confirmed')
    const startTime = performance.now()
    await connection.getRecentBlockhash()
    const endTime = performance.now()
    return Math.round(endTime - startTime)
  } catch {
    return null
  }
}

async function updatePings() {
  await Promise.allSettled(
    endpoints.map(async (endpoint) => {
      rpcPings.value[endpoint.id] = await measurePing(endpoint.url)
    }),
  )
}

function pingColor(ping?: number | null) {
  switch (true) {
    case (!ping): return '#ef643b'
    case (Number(ping) < 200): return '#03b46d'
    case (Number(ping) < 500): return '#ff9100'
    default: return '#ef643b'
  }
}

let pingInterval

watch(() => isSidebar?.value, async (s) => {
  const endpoint = connection.endpoint
  if (endpoint) {
    rpc.value = endpoint.id
  }

  clearInterval(pingInterval)
  if (s) {
    await updatePings()
    pingInterval = setInterval(updatePings, PINT_INTERVAL)
  }
})
</script>

<template>
  <div class="setting-item">
    <div class="setting-item__title">
      PRC Endpoint
    </div>

    <div class="rpc-list">
      <div v-for="endpoint in endpoints" :key="endpoint.id" class="rpc-list__item">
        <j-radio-btn v-model="rpc" :value="endpoint.id" :label="endpoint.name" />
        <div class="ping" :style="{ '--ping-color': pingColor(rpcPings[endpoint.id]) }">
          <span>{{ rpcPings[endpoint.id] ?? 0 }}ms</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.rpc-list {
  padding-top: $spacing-20;
  display: flex;
  flex-direction: column;
  gap: $spacing-12;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ping {
      position: relative;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 6px;
      color: $neutral-300;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: var(--ping-color);
        border-radius: 50%;
      }
    }
  }
}
</style>
