<script lang="ts" setup>
import { useWallet } from '@solana/wallet-adapter-vue'
import arrowRight from '~/assets/img/icons/arrow-right.svg?raw'

const { i18next } = useTranslation()

const { publicKey } = useWallet()

const subMenu = ref(false)

const isSidebar = inject<Ref<boolean>>('isSidebar')

function menuHandler() {
  subMenu.value = !subMenu.value
}

if (isSidebar) {
  watch(() => isSidebar.value, (val) => {
    if (!val) {
      subMenu.value = false
    }
  })
}

const labels = {
  en: 'English',
  de: 'Deutsch',
  jp: '日本語',
  id: 'Indonesian',
  ru: 'Русский',
  ua: 'Українська',
} as const

const keys = Object.keys(labels)
const languages = computed(() => keys.filter(key => key in i18next.store.data))
const currentLanguage = computed(() => i18next.resolvedLanguage)

function handleLanguage(lang: string) {
  i18next.changeLanguage(lang.toLowerCase())
  Analytics.track({
    event: 'select_language',
    address: publicKey.value?.toBase58(),
    language_iso2: lang,
  })
}
</script>

<template>
  <div class="setting-item language">
    <div class="setting-item__title">
      {{ $t('common.language') }}
    </div>
    <div class="language-selected-lang" @click="menuHandler">
      {{ labels[String(currentLanguage) || 'en'] }} <i v-html="arrowRight" />
    </div>
  </div>

  <sidebar-sub-menu :is-sub-menu="subMenu" :title="$t('common.language')" @close="menuHandler">
    <div class="languages-list">
      <div
        v-for="lang in languages"
        :key="lang"
        :class="{ active: lang === currentLanguage }"
        class="languages-list__item"
        @click="handleLanguage(lang)"
      >
        {{ labels[lang] }}
      </div>
    </div>
  </sidebar-sub-menu>
</template>

<style lang="scss">
.setting-item.language {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .language-selected-lang {
    color: $neutral-600;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: $spacing-8;
    cursor: pointer;

    i {
      display: flex;
      align-items: center;
      color: $neutral-600;

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: $neutral-600;
        }
      }
    }
  }
}

.languages-list {
  &__item {
    padding: $spacing-12 0;
    cursor: pointer;

    &:first-child {
      padding-top: $spacing-24;
    }

    &.active {
      font-weight: 500;
    }
  }
}

body.body--dark {
  .setting-item.language {
    .language-selected-lang {
      color: $neutral-400;
    }
  }
}
</style>
