<script lang="ts" setup>
import logoDark from '~/assets/img/logo-dark.svg'
import logo from '~/assets/img/logo.svg'
import discordIcon from '~/assets/img/social/discord-icon.svg'
import linkedInIcon from '~/assets/img/social/linkedin-icon.svg'
import telegramIcon from '~/assets/img/social/telegram-icon.svg'
import xIcon from '~/assets/img/social/x-icon.svg'
import {
  DISCLAIMER_LINK,
  DISCORD_URL,
  DOCUMENTATION_LINK,
  FAQ_LINK,
  LINKEDIN_LINK,
  MEDIAKIT_LINK,
  PUMPJACK_LINK,
  SUPPORT_LINK,
  TELEGRAM_URL,
  TERMS_LINK,
  TWITTER_URL,

} from '~/config'
import { isDark } from '~/hooks'

const logoSrc = computed(() => isDark.value ? logoDark : logo)
</script>

<template>
  <footer>
    <div class="footer-content container">
      <div class="footer-content__left">
        <router-link to="/">
          <img :src="logoSrc" alt="logo">
        </router-link>
        <span>
          Streamlined solutions, seamless experiences, and pure satisfaction.
        </span>
      </div>
      <div class="footer-content__right">
        <!-- Rewards -->
        <div class="footer-links">
          <div class="footer-links__title">
            {{ $t('footer.rewards') }}
          </div>

          <router-link to="/holders-club">
            Holders Club
          </router-link>

          <a :href="PUMPJACK_LINK" target="_blank">
            Pumpjack
          </a>
        </div>

        <!-- Resources -->
        <div class="footer-links">
          <div class="footer-links__title">
            {{ $t('footer.resources') }}
          </div>

          <router-link to="/pool-info">
            Pool Info
          </router-link>

          <a :href="DOCUMENTATION_LINK" target="_blank">
            {{ $t('footer.documentation') }}
          </a>

          <a :href="MEDIAKIT_LINK" target="_blank">
            Media Kit
          </a>
        </div>

        <!-- Help -->
        <div class="footer-links">
          <div class="footer-links__title">
            {{ $t('footer.help') }}
          </div>

          <a :href="FAQ_LINK" target="_blank">
            FAQ
          </a>

          <a :href="SUPPORT_LINK" target="_blank">
            {{ $t('footer.support') }}
          </a>
        </div>

        <!-- Services -->
        <div class="footer-links">
          <div class="footer-links__title">
            {{ $t('footer.services') }}
          </div>

          <router-link to="/validator-profit-calculator">
            Profit Calculator
          </router-link>
          <router-link to="/stake-distribution">
            Stake Distribution
          </router-link>
          <router-link to="/stake-concentration">
            Stake Concentration
          </router-link>
          <router-link to="/wormhole">
            JSOL Cross-Chain Bridge
          </router-link>
        </div>

        <!-- Community -->
        <div class="footer-links">
          <div class="footer-links__title">
            {{ $t('footer.community') }}
          </div>

          <div class="social">
            <a :href="TWITTER_URL" target="_blank">
              <img :src="xIcon" alt="x-icon">
            </a>

            <a :href="TELEGRAM_URL" target="_blank">
              <img :src="telegramIcon" alt="x-icon">
            </a>

            <a :href="DISCORD_URL" target="_blank">
              <img :src="discordIcon" alt="x-icon">
            </a>

            <a :href="LINKEDIN_LINK" target="_blank">
              <img :src="linkedInIcon" alt="x-icon">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="footer-bottom__content container">
        <div class="copyright">
          {{ $t('footer.copyright', { date: new Date().getFullYear() }) }}
        </div>

        <a :href="DISCLAIMER_LINK" target="_blank">
          Disclaimer
        </a>

        <a :href="TERMS_LINK" target="_blank">
          Terms of Service
        </a>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
footer {
  background-color: #fff;
  margin-top: auto;
  overflow: hidden;
}
.footer-content {
  padding-top: $spacing-120;
  padding-bottom: $spacing-120;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $spacing-64;

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    padding-top: $spacing-64;
    padding-bottom: $spacing-64;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
    width: 320px;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    a {
      width: 134px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $neutral-600;
    }
  }

  &__right {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: $spacing-40;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    @media (max-width: $breakpoint-xs) {
      display: flex;

      flex-wrap: wrap;
    }

    .footer-links {
      display: flex;
      flex-direction: column;
      gap: $spacing-16;

      @media (max-width: $breakpoint-md) {
        flex: 1;
      }

      @media (max-width: $breakpoint-xs) {
        flex-basis: 40%;

        &:last-child {
          align-items: center;
        }
      }

      &__title {
        color: $neutral-600;
      }

      a {
        color: $black;
        font-weight: 500;
        text-decoration: none;

        &:hover {
          opacity: 0.6;
        }
      }

      .social {
        display: flex;
        gap: $spacing-16;

        a {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.footer-bottom {
  border-top: 1px solid $neutral-200;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 44px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $neutral-600;
    padding-top: $spacing-20;
    padding-bottom: $spacing-20;

    @media (max-width: $breakpoint-md) {
      padding-bottom: calc($spacing-20 + 88px);
    }

    @media (max-width: $breakpoint-xs) {
      padding-bottom: calc($spacing-20 + 60px);
      flex-wrap: wrap;
      gap: $spacing-12;
      justify-content: center;
      text-align: center;
    }

    .copyright {
      margin-right: auto;

      @media (max-width: $breakpoint-xs) {
        width: 100%;
      }
    }

    a {
      color: $neutral-600;
      @media (max-width: $breakpoint-xs) {
        margin: 0;
        padding: 0 $spacing-8;
      }
    }
  }
}

body.body--dark {
  footer {
    background-color: $dark-bg;

    .footer-content__left span,
    .footer-links__title,
    .footer-bottom__content {
      color: $neutral-400;
    }

    .footer-links a {
      color: #fff;
    }

    .social img {
      filter: invert(1);
    }

    .footer-bottom {
      border-color: $neutral-800;
    }
  }
}
</style>
