<script lang="ts" setup>
import { useWallet } from '@solana/wallet-adapter-vue'
import refreshIcon from '~/assets/img/icons/refresh-icon.svg?raw'

const { connected } = useWallet()
const stakeAccountStore = useStakeAccountStore()

const loading = computed(() => stakeAccountStore.loading)

const stakesCount = computed(() => stakeAccountStore.accountsFull.length)

function refreshStakes() {
  stakeAccountStore.load()
}
</script>

<template>
  <div class="setting-item staking-accounts">
    <div class="setting-item__title">
      {{ $t('sidebar.myStakingAccounts') }}
      <j-tooltip v-if="connected">
        <i class="refresh-stakes-btn" @click="refreshStakes" v-html="refreshIcon" />
        <template #content>
          {{ $t('sidebar.refresh') }}
        </template>
      </j-tooltip>
    </div>

    <j-skeleton v-if="loading" width="100%" height="152" class="staking-card" />
    <staking-sol v-if="!loading && connected" @refresh-stakes="refreshStakes" />
    <!-- <staking-direct v-if="!loading && connected" @refresh-stakes="refreshStakes" /> -->
    <div v-if="!loading && stakesCount === 0" class="no-stake-accounts">
      <span v-html="$t('sidebar.noAccounts')" />
    </div>
  </div>
</template>

<style lang="scss">
.setting-item.staking-accounts {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;

  .setting-item__title {
    display: flex;
    align-items: center;
    gap: $spacing-8;
  }

  .refresh-stakes-btn {
    width: 24px;
    height: 24px;
    border-radius: $spacing-4;
    padding: $spacing-4;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .no-stake-accounts {
    text-align: center;
    font-size: 14px;
  }
}

body.body--dark {
  .setting-item.staking-accounts .refresh-stakes-btn {
    background-color: $neutral-900;

    svg path {
      stroke: #fff;
    }
  }
}
</style>
