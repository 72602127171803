<script lang="ts" setup>
import { PriorityMode } from '../constants'
import { usePriorityFee } from '../index'

const { t } = useTranslation()

const priorityFee = usePriorityFee()

const modes = computed(() => {
  return [
    { label: t('priorityFee.maxCap'), value: PriorityMode.MaxCap },
    { label: t('priorityFee.exactFee'), value: PriorityMode.ExactFee },
  ]
})

const tooltip = computed(() => {
  return {
    [t('priorityFee.maxCap')]: t('priorityFee.maxCapInfo'),
    [t('priorityFee.exactFee')]: t('priorityFee.exactInfo'),
  }
})

const mode = ref()

watch([
  () => priorityFee.mode,
  () => modes.value,
], ([m]) => {
  if (typeof m === 'number') {
    mode.value = modes.value.find(v => v.value === m)
  }
}, { immediate: true })

watch(mode, (m) => {
  if (m) {
    priorityFee.mode = m.value
  }
})
</script>

<template>
  <j-btn-group v-model="mode" :buttons="modes">
    <template #default="{ label, isActive, select }">
      <j-btn :variant="isActive ? 'primary' : 'secondary'" @click="select">
        {{ label }} <info-tooltip :text="String(tooltip[label])" :icon-color="isActive ? '#111' : '#c7c7c7'" />
      </j-btn>
    </template>
  </j-btn-group>
</template>
