<script lang="ts" setup>
import logoDark from '~/assets/img/logo-dark.svg'
import logo from '~/assets/img/logo.svg'
import { LANDING_LINK } from '~/config'
import { isDark } from '~/hooks'

const logoSrc = computed(() => isDark.value ? logoDark : logo)
const isSidebar = ref(false)

function sidebarHandler() {
  isSidebar.value = !isSidebar.value
}
</script>

<template>
  <header class="header">
    <div class="header-wrapper container">
      <a :href="LANDING_LINK" class="logo">
        <img :src="logoSrc" alt="jpool logo">
      </a>

      <nav-tabs />

      <connect-wallet with-icon @clic-btn="sidebarHandler" />
      <settings-btn />
    </div>
  </header>

  <wallet-sidebar :is-sidebar="isSidebar" @close="sidebarHandler" />
</template>

<style lang="scss">
.header {
  position: relative;
  // padding-top: $spacing-20;
  // padding-bottom: $spacing-20;
  background-color: #fff;

  &-wrapper {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-12;
  }

  .logo {
    position: relative;

    display: flex;

    img {
      width: 134px;
      height: 28px;
      object-fit: contain;

      @media (max-width: $breakpoint-xs) {
        width: 95px;
        height: 20px;
      }
    }
  }
}

body.body--dark {
  .header {
    background-color: $dark-bg;
  }
}
</style>
