<script lang="ts" setup>
import jsolIcon from '~/assets/img/tokens/jsol.svg'
import solIcon from '~/assets/img/tokens/sol.svg'
import { formatPrice } from '~/utils'

const balanceStore = useBalanceStore()
const solBalance = computed(() => balanceStore.solBalance || 0)
const jsolBalance = computed(() => balanceStore.tokenBalance || 0)

const coinRateStore = useCoinRateStore()
const solPrice = computed(() => coinRateStore.solana?.price)
const jsolPrice = computed(() => coinRateStore.jpool?.price)

const solAmountPrice = computed(() => formatPrice(Number(solBalance.value * solPrice.value), 2, 2))
const jsolAmountPrice = computed(() => formatPrice(Number(jsolBalance.value * jsolPrice.value), 2, 2))
</script>

<template>
  <div class="setting-item">
    <div class="setting-item__title">
      {{ $t('sidebar.myAssets') }}
    </div>

    <div class="assets-wrapper">
      <div class="assets-item">
        <img :src="jsolIcon" alt="">
        <span class="amount">{{ jsolBalance }}</span>
        <span class="price">${{ jsolAmountPrice }}</span>
      </div>
      <div class="assets-item">
        <img :src="solIcon" alt="">
        <span class="amount">{{ solBalance }}</span>
        <span class="price">${{ solAmountPrice }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.setting-item {
  .assets-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-12;
    padding-top: $spacing-24;

    .assets-item {
      display: flex;
      align-items: center;
      gap: $spacing-8;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      .price {
        margin-left: auto;
        color: $neutral-300;
      }
    }
  }
}

body.body--dark {
  .setting-item .assets-wrapper .assets-item .price {
    color: $neutral-500;
  }
}
</style>
